import { createRef, lazy } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

// Settings
import versionObj from "./version.json";

// Styles
import "./scss/App.scss";

// Utils
import settings from "./settings.json";

// Interfaces
import { role } from "./interfaces/Base";

// Hooks
import { useFetchUser } from "./hooks/useFetchUser";
import { SnackbarProvider } from "notistack";

// Mui
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// Custom components
import NavigationScroll from "./components/layout/NavigationScroll";
import Loadable from "./components/general/Loadable";
import themes from "./themes";

// Pages
const Home = Loadable(lazy(() => import("./components/pages/Home")));
const MainLayout = Loadable(lazy(() => import("./components/layout/MainLayout")));
const MinimalLayout = Loadable(lazy(() => import("./components/layout/MinimalLayout")));
const Login = Loadable(lazy(() => import("./components/pages/auth/Login")));
const Admin = Loadable(lazy(() => import("./components/pages/admin/Admin")));
const Admins = Loadable(lazy(() => import("./components/pages/admins/Admins")));
const Register = Loadable(lazy(() => import("./components/pages/auth/Register")));
const Logs = Loadable(lazy(() => import("./components/pages/logs/Logs")));
const ForgotPassword = Loadable(lazy(() => import("./components/pages/auth/ForgotPassword")));
const ChangePassword = Loadable(lazy(() => import("./components/pages/auth/ChangePassword")));
const Events = Loadable(lazy(() => import("./components/pages/events/Events")));
const Event = Loadable(lazy(() => import("./components/pages/events/Event")));
const PendingEvents = Loadable(lazy(() => import("./components/pages/events/PendingEvents")));
const Polls = Loadable(lazy(() => import("./components/pages/polls/Polls")));
const Poll = Loadable(lazy(() => import("./components/pages/polls/Poll")));
const NewsAndPromotions = Loadable(lazy(() => import("./components/pages/newsAndPromotions/NewsAndPromotions")));
const LycQuestions = Loadable(lazy(() => import("./components/pages/lyc/LycQuestions")));
const LycQuestion = Loadable(lazy(() => import("./components/pages/lyc/LycQuestion")));
const EFs = Loadable(lazy(() => import("./components/pages/lyc/EFs")));
const Donations = Loadable(lazy(() => import("./components/pages/donations/Donations")));

const PrivateRoute = ({ title, rolesAllowed = [], children }: any) => {
    const { currentAdmin } = useFetchUser();

    document.title = `${title} - ${settings.app.title}`;

    // Hack-ish code to return you to the page you were before refresh
    if (!currentAdmin) {
        if (!window.location.href.includes("/login")) {
            return <Navigate to="/login" />;
        }
    }

    return currentAdmin && rolesAllowed.some((r: string) => currentAdmin.roles.includes(r as role)) ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ title, children }: any) => {
    document.title = `${title} - ${settings.app.title}`;
    return children;
};

const App: React.FC<any> = () => {
    console.log(`%cVersion: ${versionObj && versionObj.version}`, "color: #bada55");

    const snackbarRef: any = createRef();

    return (
        <StyledEngineProvider injectFirst>
            <SnackbarProvider
                className="snackbar__container"
                ref={snackbarRef}
                maxSnack={3}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                autoHideDuration={2000}
            >
                <ThemeProvider theme={themes()}>
                    <CssBaseline />
                    <NavigationScroll>
                        <BrowserRouter>
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <PrivateRoute title="Home" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <Home />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="admins"
                                    element={
                                        <PrivateRoute title="Admins" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <Admins />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route path="admin">
                                    <Route path=":id">
                                        <Route
                                            path=":index"
                                            element={
                                                <PrivateRoute title="Admin" rolesAllowed={["superAdmin", "admin"]}>
                                                    <MainLayout>
                                                        <Admin />
                                                    </MainLayout>
                                                </PrivateRoute>
                                            }
                                        />
                                    </Route>
                                </Route>

                                <Route
                                    path="logs"
                                    element={
                                        <PrivateRoute title="Logs" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <Logs />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="events"
                                    element={
                                        <PrivateRoute title="Events" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <Events />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route path="events">
                                    <Route
                                        path=":index"
                                        element={
                                            <PrivateRoute title="User" rolesAllowed={["superAdmin", "admin"]}>
                                                <MainLayout>
                                                    <Event />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="pendingEvents"
                                    element={
                                        <PrivateRoute title="Pending events" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <PendingEvents />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route path="pendingEvents">
                                    <Route
                                        path=":index"
                                        element={
                                            <PrivateRoute title="Pending event" rolesAllowed={["superAdmin", "admin"]}>
                                                <MainLayout>
                                                    <Event />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="polls"
                                    element={
                                        <PrivateRoute title="Polls" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <Polls />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="polls">
                                    <Route
                                        path=":index"
                                        element={
                                            <PrivateRoute title="Poll" rolesAllowed={["superAdmin", "admin"]}>
                                                <MainLayout>
                                                    <Poll />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="newsAndPromotions"
                                    element={
                                        <PrivateRoute title="News and Promotions" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <NewsAndPromotions />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="lycQuestions"
                                    element={
                                        <PrivateRoute title="Lyc" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <LycQuestions />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="lycQuestions">
                                    <Route
                                        path=":index"
                                        element={
                                            <PrivateRoute title="LycQuestion" rolesAllowed={["superAdmin", "admin"]}>
                                                <MainLayout>
                                                    <LycQuestion />
                                                </MainLayout>
                                            </PrivateRoute>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="EFs"
                                    element={
                                        <PrivateRoute title="EFs" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <EFs />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="donations"
                                    element={
                                        <PrivateRoute title="EFs" rolesAllowed={["superAdmin", "admin"]}>
                                            <MainLayout>
                                                <Donations />
                                            </MainLayout>
                                        </PrivateRoute>
                                    }
                                />

                                <Route
                                    path="login"
                                    element={
                                        <PublicRoute title="Login">
                                            <MinimalLayout>
                                                <Login />
                                            </MinimalLayout>
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="register"
                                    element={
                                        <PublicRoute title="Register">
                                            <MinimalLayout>
                                                <Register />
                                            </MinimalLayout>
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/forgotPassword"
                                    element={
                                        <PublicRoute title="Forgot password">
                                            <ForgotPassword />
                                        </PublicRoute>
                                    }
                                />
                                <Route
                                    path="/changePassword"
                                    element={
                                        <PublicRoute title="Change password">
                                            <ChangePassword />
                                        </PublicRoute>
                                    }
                                />
                                <Route path="*" element={<Login />} />
                            </Routes>
                        </BrowserRouter>
                    </NavigationScroll>
                </ThemeProvider>
            </SnackbarProvider>
        </StyledEngineProvider>
    );
};

export default App;
