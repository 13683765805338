import serviceConfig from "./firebaseConfig.json";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

initializeApp(serviceConfig);

export const auth = getAuth();
export const firestore = getFirestore();
export const storage = getStorage();
export const functions = getFunctions();

// connectFunctionsEmulator(functions, "127.0.0.1", 5001);
// connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
// connectAuthEmulator(auth, "http://127.0.0.1:9099");
